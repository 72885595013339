html {
  background-color: #d8ddef;
  font-weight: 200;
  color: #333333;
  padding: 15px 15px 45px;
}

a {
  font-size: 18px;
  color: #45b69c;
  font-weight: 700;
}

a:visited {
  color: #45b69c;
}

.App {
  text-align: center;
  font-family: "Manrope", sans-serif;
}

h1 {
  font-weight: bold;
  font-family: "Karla", sans-serif;
  color: #45b69c;
  text-transform: uppercase;
  display: inline-block;
  font-size: 32px;
}

h2 {
  text-decoration: overline;
  text-decoration-color: #7293a0;
}

h3 {
  margin: 0;
}

h1,
h2,
h4 {
  margin: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input {
  font-size: 18px;
}
